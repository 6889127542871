export default function MilkCard() {
  return (
    <div className="flex flex-col justify-center items-center rounded-2xl relative min-w-[8rem] w-32 h-32 bg-[#CCE9FF]">
      <svg
        width="65"
        height="64"
        viewBox="0 0 65 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0 right-0"
      >
        <path
          opacity="0.2"
          d="M37.8535 -22.6088C42.4751 -26.802 49.5249 -26.802 54.1465 -22.6088L56.0134 -20.915C58.3641 -18.7823 61.4574 -17.6565 64.6289 -17.7792L67.1479 -17.8767C73.3835 -18.1181 78.7839 -13.5867 79.629 -7.40379L79.9704 -4.9062C80.4003 -1.76149 82.0461 1.08926 84.5546 3.03386L86.5469 4.57829C91.4789 8.40161 92.703 15.3442 89.3762 20.6238L88.0322 22.7565C86.3401 25.4418 85.7685 28.6835 86.4402 31.7856L86.9736 34.2493C88.2941 40.3484 84.7693 46.4536 78.8271 48.3595L76.4267 49.1294C73.4044 50.0988 70.8828 52.2147 69.4033 55.0227L68.2283 57.2529C65.3195 62.7739 58.6949 65.185 52.9179 62.8255L50.5842 61.8723C47.6459 60.6722 44.3541 60.6722 41.4158 61.8723L39.0821 62.8255C33.3051 65.1851 26.6805 62.7739 23.7717 57.2529L22.5967 55.0227C21.1172 52.2147 18.5956 50.0988 15.5733 49.1294L13.1729 48.3595C7.23072 46.4536 3.70587 40.3484 5.0264 34.2493L5.55983 31.7856C6.23147 28.6835 5.65986 25.4418 3.96776 22.7565L2.62385 20.6238C-0.703037 15.3442 0.52114 8.40161 5.45311 4.57829L7.44539 3.03386C9.95387 1.08926 11.5998 -1.7615 12.0296 -4.9062L12.371 -7.40379C13.2161 -13.5867 18.6165 -18.1181 24.8522 -17.8767L27.3711 -17.7792C30.5427 -17.6565 33.6359 -18.7823 35.9866 -20.915L37.8535 -22.6088Z"
          fill="#8BC4EF"
        />
      </svg>

      <div className="z-10 relative items-center flex flex-col">
        <p className="text-3xl">🥛</p>
        <p className="font-bold text-lg text-[#444038]">Dairy-free</p>
      </div>

      <svg
        width="43"
        height="73"
        viewBox="0 0 43 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute bottom-0 left-0"
      >
        <path
          opacity="0.2"
          d="M-5.80032 3.58539C-3.02468 -0.522993 3.02468 -0.522992 5.80032 3.58539L7.91321 6.71281C9.71011 9.3725 13.0857 10.4693 16.1027 9.37374L19.6503 8.08554C24.3107 6.39326 29.2048 9.94899 29.0355 14.9042L28.9066 18.6763C28.797 21.8842 30.8832 24.7556 33.968 25.6427L37.5952 26.6858C42.3603 28.056 44.2296 33.8093 41.18 37.7186L38.8586 40.6945C36.8844 43.2254 36.8844 46.7746 38.8586 49.3055L41.18 52.2814C44.2296 56.1907 42.3603 61.944 37.5952 63.3142L33.968 64.3573C30.8832 65.2444 28.797 68.1158 28.9066 71.3237L29.0355 75.0958C29.2048 80.051 24.3107 83.6067 19.6503 81.9145L16.1027 80.6263C13.0857 79.5307 9.71011 80.6275 7.91321 83.2872L5.80032 86.4146C3.02468 90.523 -3.02468 90.523 -5.80032 86.4146L-7.91321 83.2872C-9.71011 80.6275 -13.0857 79.5307 -16.1027 80.6263L-19.6503 81.9145C-24.3107 83.6067 -29.2048 80.051 -29.0355 75.0958L-28.9066 71.3237C-28.797 68.1158 -30.8832 65.2444 -33.968 64.3573L-37.5952 63.3142C-42.3603 61.944 -44.2296 56.1907 -41.18 52.2814L-38.8586 49.3055C-36.8844 46.7746 -36.8844 43.2254 -38.8586 40.6945L-41.18 37.7186C-44.2296 33.8093 -42.3603 28.056 -37.5952 26.6858L-33.968 25.6427C-30.8832 24.7556 -28.797 21.8842 -28.9066 18.6763L-29.0355 14.9042C-29.2048 9.94899 -24.3107 6.39326 -19.6503 8.08554L-16.1027 9.37374C-13.0857 10.4693 -9.71011 9.3725 -7.91321 6.71281L-5.80032 3.58539Z"
          fill="#8BC4EF"
        />
      </svg>
    </div>
  );
}
