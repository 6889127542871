import CartIcon from "@/ui/Icons/CartIcon";
import CartSolidIcon from "@/ui/Icons/CartSolid";
import ExploreOutlineIcon from "@/ui/Icons/ExploreOutline";
import ExploreSolidIcon from "@/ui/Icons/ExploreSolid";
import HomeOutlineIcon from "@/ui/Icons/HomeOutline";
import HomeSolidIcon from "@/ui/Icons/HomeSolid";
import LibraryIcon from "@/ui/Icons/LibraryIcon";
import LibrarySolidIcon from "@/ui/Icons/LibrarySolidIcon";
import Link from "next/link";
import { useRouter } from "next/router";

export default function BottomNav({ onClickCart = null }: any) {
  const router = useRouter();
  const path = router?.asPath;
  return (
    <div className="flex items-center justify-evenly gap-2 px-3 py-2 fixed bottom-0 left-1/2 -translate-x-1/2 z-20 w-screen max-w-[34rem] bg-white pb-safe border border-gray-300">
      <Link href="/">
        <div className="flex items-center flex-col">
          {path == "/" ? <HomeSolidIcon /> : <HomeOutlineIcon />}
          <p
            className={
              path == "/" ? "text-[#15BC6E] font-medium" : "text-gray-600"
            }
          >
            Home
          </p>
        </div>
      </Link>
      <Link href="swipe">
        <div className="flex items-center flex-col">
          {path == "/swipe" ? <ExploreSolidIcon /> : <ExploreOutlineIcon />}
          <p
            className={
              path == "/swipe" ? "text-[#15BC6E] font-medium" : "text-gray-600"
            }
          >
            Explore
          </p>
        </div>
      </Link>
      <Link href="library">
        <div className="flex items-center flex-col">
          {path == "/library" ? <LibrarySolidIcon /> : <LibraryIcon />}
          <p
            className={
              path == "/library"
                ? "text-[#15BC6E] font-medium"
                : "text-gray-600"
            }
          >
            Library
          </p>
        </div>
      </Link>
      {/* <Link href="shoppingcart"> */}
      <div
        className="flex items-center flex-col"
        onClick={() => {
          if (onClickCart !== null) {
            onClickCart();
          } else {
            router.push("/shoppingcart");
          }
        }}
      >
        {path == "/shoppingcart" ? <CartSolidIcon /> : <CartIcon />}
        <p
          className={
            path == "/shoppingcart"
              ? "text-[#15BC6E] font-medium"
              : "text-gray-600"
          }
        >
          Cart
        </p>
      </div>
      {/* </Link> */}
    </div>
  );
}
