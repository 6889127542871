import Link from "next/link";

export default function VegCard() {
  return (
    <Link href="/search?filter=🥗 Vegetarian">
      <div className="flex flex-col justify-center items-center rounded-2xl relative min-w-[8rem] w-32 h-32 bg-[#E7FFB8]">
        <svg
          width="50"
          height="83"
          viewBox="0 0 50 83"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 right-0"
        >
          <path
            opacity="0.05"
            d="M5.24036 41.1541C-0.858546 38.4247 -0.270633 29.581 6.13589 27.6829L28.1521 21.1602C30.1663 20.5635 31.8193 19.1166 32.6774 17.1991L42.057 -3.76001C44.7863 -9.85892 53.6301 -9.271 55.5281 -2.86447L62.0508 19.1518C62.6476 21.1659 64.0945 22.8189 66.012 23.677L86.9711 33.0566C93.07 35.786 92.4821 44.6297 86.0755 46.5278L64.0593 53.0505C62.0451 53.6472 60.3921 55.0941 59.534 57.0116L50.1544 77.9707C47.4251 84.0696 38.5813 83.4817 36.6833 77.0752L30.1606 55.0589C29.5638 53.0447 28.1169 51.3918 26.1995 50.5337L5.24036 41.1541Z"
            fill="#40690E"
          />
        </svg>

        <div className="z-10 relative items-center flex flex-col">
          <p className="text-3xl">🥗</p>
          <p className="font-bold text-lg text-[#004707]">Vegetarian</p>
        </div>

        <svg
          width="48"
          height="72"
          viewBox="0 0 48 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 left-0"
        >
          <path
            opacity="0.05"
            d="M-22.8689 42.8966C-27.9595 40.9355 -27.8904 33.6239 -22.7638 31.7593L-5.1458 25.3515C-3.53399 24.7653 -2.25556 23.4958 -1.64589 21.8762L5.01815 4.17267C6.95732 -0.978895 14.1828 -0.910649 16.0244 4.27663L22.3529 22.1029C22.9318 23.7337 24.1861 25.0271 25.7865 25.6436L43.2803 32.383C48.3709 34.3441 48.3018 41.6557 43.1751 43.5203L25.5572 49.9281C23.9454 50.5143 22.6669 51.7838 22.0573 53.4034L15.3932 71.1069C13.4541 76.2585 6.22853 76.1903 4.38699 71.003L-1.9415 53.1768C-2.52047 51.5459 -3.7747 50.2525 -5.37515 49.636L-22.8689 42.8966Z"
            fill="#40690E"
          />
        </svg>
      </div>
    </Link>
  );
}
