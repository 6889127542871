export default function CartSolidIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3316_7124)">
        <path
          d="M0.495426 0.637974C0.495426 0.467478 0.563156 0.303965 0.683715 0.183406C0.804274 0.0628464 0.967787 -0.00488281 1.13828 -0.00488281H1.70143C2.63614 -0.00488281 3.17357 0.60326 3.48471 1.21012C3.69685 1.6254 3.84985 2.13197 3.97714 2.57169H17.2136C17.4122 2.57175 17.6082 2.61784 17.786 2.70636C17.9639 2.79487 18.1188 2.9234 18.2387 3.08185C18.3585 3.2403 18.44 3.42437 18.4767 3.61961C18.5135 3.81485 18.5045 4.01594 18.4504 4.20712L16.527 10.9893C16.374 11.5273 16.0497 12.0009 15.6033 12.338C15.1569 12.6751 14.6127 12.8575 14.0533 12.8574H7.52314C6.95907 12.8576 6.41058 12.6723 5.96218 12.3301C5.51378 11.9879 5.19035 11.5077 5.04171 10.9635L4.21628 7.93569C4.21155 7.92212 4.20726 7.9084 4.20343 7.89455L2.87785 3.3984L2.74928 2.96512C2.62071 2.52026 2.50757 2.12683 2.33785 1.7964C2.13343 1.39783 1.94571 1.28212 1.70014 1.28212H1.137C0.966501 1.28212 0.802988 1.21439 0.682429 1.09383C0.56187 0.973269 0.494141 0.809756 0.494141 0.63926L0.495426 0.637974ZM7.57071 18.0003C8.0822 18.0003 8.57274 17.7971 8.93442 17.4354C9.29609 17.0737 9.49928 16.5832 9.49928 16.0717C9.49928 15.5602 9.29609 15.0697 8.93442 14.708C8.57274 14.3463 8.0822 14.1431 7.57071 14.1431C7.05922 14.1431 6.56868 14.3463 6.20701 14.708C5.84533 15.0697 5.64214 15.5602 5.64214 16.0717C5.64214 16.5832 5.84533 17.0737 6.20701 17.4354C6.56868 17.7971 7.05922 18.0003 7.57071 18.0003ZM13.9993 18.0003C14.5108 18.0003 15.0013 17.7971 15.363 17.4354C15.7247 17.0737 15.9279 16.5832 15.9279 16.0717C15.9279 15.5602 15.7247 15.0697 15.363 14.708C15.0013 14.3463 14.5108 14.1431 13.9993 14.1431C13.4878 14.1431 12.9973 14.3463 12.6356 14.708C12.2739 15.0697 12.0707 15.5602 12.0707 16.0717C12.0707 16.5832 12.2739 17.0737 12.6356 17.4354C12.9973 17.7971 13.4878 18.0003 13.9993 18.0003Z"
          fill="#15BC6E"
        />
      </g>
      <defs>
        <clipPath id="clip0_3316_7124">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
