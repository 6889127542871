import Link from "next/link";

export default function RawCard() {
  return (
    <Link href="/search?filter=🥩 Raw">
      <div className="flex flex-col justify-center items-center rounded-2xl relative min-w-[8rem] w-32 h-32 bg-[#FFE3DC]">
        <svg
          width="41"
          height="64"
          viewBox="0 0 41 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 right-0"
        >
          <path
            opacity="0.3"
            d="M35.4 -14.8412C37.6161 -16.1701 40.384 -16.1701 42.6 -14.8412L48.7689 -11.1419C49.6773 -10.5972 50.6976 -10.2657 51.7527 -10.1724L58.9178 -9.53922C61.4917 -9.31175 63.731 -7.68482 64.7427 -5.30717L67.5591 1.31161C67.9738 2.28629 68.6044 3.15414 69.4032 3.84977L74.8277 8.57361C76.7764 10.2705 77.6317 12.903 77.0526 15.4212L75.4407 22.4313C75.2033 23.4636 75.2033 24.5364 75.4407 25.5687L77.0526 32.5788C77.6317 35.097 76.7764 37.7295 74.8277 39.4264L69.4032 44.1502C68.6044 44.8459 67.9738 45.7137 67.5591 46.6884L64.7427 53.3072C63.731 55.6848 61.4917 57.3118 58.9178 57.5392L51.7527 58.1724C50.6976 58.2657 49.6773 58.5972 48.7689 59.1419L42.6 62.8412C40.3839 64.1701 37.616 64.1701 35.4 62.8412L29.2311 59.1419C28.3227 58.5972 27.3024 58.2657 26.2473 58.1724L19.0822 57.5392C16.5083 57.3118 14.269 55.6848 13.2573 53.3072L10.4409 46.6884C10.0262 45.7137 9.39562 44.8459 8.59681 44.1502L3.17228 39.4264C1.22364 37.7295 0.368309 35.097 0.947362 32.5788L2.5593 25.5687C2.79667 24.5364 2.79667 23.4636 2.5593 22.4313L0.947362 15.4212C0.368309 12.903 1.22364 10.2705 3.17228 8.57361L8.59681 3.84977C9.39563 3.15414 10.0262 2.28629 10.4409 1.31161L13.2573 -5.30717C14.269 -7.68482 16.5083 -9.31175 19.0822 -9.53922L26.2473 -10.1724C27.3024 -10.2657 28.3227 -10.5972 29.2311 -11.1419L35.4 -14.8412Z"
            fill="white"
          />
        </svg>

        <div className="z-10 relative items-center flex flex-col">
          <p className="text-3xl">🥩</p>
          <p className="font-bold text-lg text-[#7F130B]">Raw</p>
        </div>

        <svg
          width="65"
          height="78"
          viewBox="0 0 65 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 left-0"
        >
          <path
            opacity="0.4"
            d="M17.9 1.1588C20.116 -0.170089 22.884 -0.17009 25.1 1.1588L32.7645 5.75497C33.673 6.29973 34.6932 6.63122 35.7483 6.72446L44.6507 7.51119C47.2246 7.73866 49.4639 9.36559 50.4756 11.7432L53.9748 19.9667C54.3895 20.9414 55.02 21.8093 55.8188 22.5049L62.5585 28.374C64.5072 30.0709 65.3625 32.7034 64.7834 35.2216L62.7807 43.9313C62.5433 44.9636 62.5433 46.0364 62.7807 47.0687L64.7834 55.7784C65.3625 58.2966 64.5072 60.9291 62.5585 62.626L55.8188 68.4951C55.02 69.1907 54.3895 70.0586 53.9748 71.0333L50.4756 79.2568C49.4638 81.6344 47.2246 83.2614 44.6507 83.4888L35.7483 84.2755C34.6932 84.3688 33.673 84.7003 32.7645 85.245L25.1 89.8412C22.884 91.1701 20.116 91.1701 17.9 89.8412L10.2355 85.245C9.32702 84.7003 8.3068 84.3688 7.25167 84.2755L-1.65065 83.4888C-4.22457 83.2613 -6.46385 81.6344 -7.47556 79.2568L-10.9748 71.0333C-11.3895 70.0586 -12.02 69.1907 -12.8188 68.4951L-19.5585 62.626C-21.5072 60.9291 -22.3625 58.2966 -21.7834 55.7784L-19.7807 47.0687C-19.5433 46.0364 -19.5433 44.9636 -19.7807 43.9313L-21.7835 35.2216C-22.3625 32.7034 -21.5072 30.0709 -19.5585 28.374L-12.8188 22.5049C-12.02 21.8093 -11.3895 20.9414 -10.9748 19.9667L-7.47556 11.7432C-6.46384 9.36558 -4.22457 7.73866 -1.65065 7.51119L7.25167 6.72446C8.30681 6.63122 9.32702 6.29973 10.2355 5.75497L17.9 1.1588Z"
            fill="white"
          />
        </svg>
      </div>
    </Link>
  );
}
