export default function RoundedArrowBack() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.8332C14.6024 18.8332 18.3333 15.1022 18.3333 10.4998C18.3333 5.89746 14.6024 2.1665 10 2.1665C5.39763 2.1665 1.66667 5.89746 1.66667 10.4998C1.66667 15.1022 5.39763 18.8332 10 18.8332Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.05 13.4414L8.11667 10.4998L11.05 7.55811"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
