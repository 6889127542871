export default function BowlIcon({ size = 18, ...props }) {
  return (
    <svg {...props} width={size} viewBox="0 0 21 15" fill="none">
      <path
        d="M7.39787 4.88151C6.12427 5.50245 5.07529 6.48022 4.36482 7.70866C4.24927 7.90803 4.31763 8.16276 4.51658 8.27792C4.58251 8.31616 4.65412 8.33409 4.7249 8.33409C4.86896 8.33409 5.00892 8.25964 5.08583 8.12616C5.7133 7.04178 6.63939 6.17874 7.76326 5.63022C7.96998 5.52932 8.05584 5.27987 7.9549 5.07319C7.85482 4.86647 7.60623 4.77979 7.39787 4.88151Z"
        fill="#343233"
      />
      <path
        d="M20.0833 10.8335H19.2289C19.0274 6.58709 15.7928 3.12928 11.6439 2.58377C11.7113 2.43033 11.75 2.26154 11.75 2.0835C11.75 1.3942 11.1893 0.833496 10.5 0.833496C9.8107 0.833496 9.25 1.3942 9.25 2.0835C9.25 2.26158 9.28871 2.43033 9.35609 2.58377C5.20719 3.12928 1.97258 6.58709 1.77109 10.8335H0.91668C0.686367 10.8335 0.5 11.0199 0.5 11.2502V11.6677C0.5 12.2947 0.683906 12.9022 1.03223 13.4247C1.34188 13.8894 1.86027 14.1669 2.41895 14.1669H18.5811C19.1393 14.1669 19.6581 13.8894 19.9682 13.4243C20.3161 12.9018 20.5 12.2947 20.5 11.6677V11.2502C20.5 11.0199 20.3136 10.8335 20.0833 10.8335ZM10.5 1.66686C10.7299 1.66686 10.9167 1.85361 10.9167 2.08353C10.9167 2.31342 10.7299 2.50021 10.5 2.50021C10.2701 2.50021 10.0833 2.31346 10.0833 2.08353C10.0833 1.85361 10.2701 1.66686 10.5 1.66686ZM10.5 3.3335C14.7246 3.3335 18.1768 6.6624 18.3955 10.8335H2.60445C2.8232 6.6624 6.27539 3.3335 10.5 3.3335ZM19.6667 11.6677C19.6667 12.1295 19.5312 12.5775 19.2744 12.9624C19.1194 13.1948 18.8602 13.3335 18.5811 13.3335H2.41895C2.1398 13.3335 1.8802 13.1948 1.7252 12.962C1.46883 12.5771 1.33336 12.1295 1.33336 11.6677V11.6669H19.6667V11.6677Z"
        fill="#343233"
      />
    </svg>
  );
}
