export default function HomeSolidIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7352 4.33792L11.5512 0.710919C10.1382 -0.279081 7.96923 -0.22508 6.61023 0.82792L2.10123 4.34692C1.20123 5.04892 0.490234 6.48892 0.490234 7.62292V13.8329C0.490234 16.1279 2.35323 17.9999 4.64823 17.9999H14.3502C16.6452 17.9999 18.5082 16.1369 18.5082 13.8419V7.73992C18.5082 6.52492 17.7252 5.03092 16.7352 4.33792ZM10.1742 14.3999C10.1742 14.7689 9.86823 15.0749 9.49924 15.0749C9.13023 15.0749 8.82423 14.7689 8.82423 14.3999V11.6999C8.82423 11.3309 9.13023 11.0249 9.49924 11.0249C9.86823 11.0249 10.1742 11.3309 10.1742 11.6999V14.3999Z"
        fill="#15BC6E"
      />
    </svg>
  );
}
