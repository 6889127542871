export default function LibraryIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7521 0.190019C12.3831 -0.178981 11.7441 0.0730192 11.7441 0.586019V3.72702C11.7441 5.04102 12.8601 6.13002 14.2191 6.13002C15.0741 6.13902 16.2621 6.13902 17.2791 6.13902C17.7921 6.13902 18.0621 5.53602 17.7021 5.17602C16.4061 3.87102 14.0841 1.52202 12.7521 0.190019Z"
        stroke="black"
      />
      <path
        d="M16.9832 7.371H14.3822C12.2492 7.371 10.5122 5.634 10.5122 3.501V0.9C10.5122 0.405 10.1072 0 9.6122 0H5.7962C3.0242 0 0.783203 1.8 0.783203 5.013V12.987C0.783203 16.2 3.0242 18 5.7962 18H12.8702C15.6422 18 17.8832 16.2 17.8832 12.987V8.271C17.8832 7.776 17.4782 7.371 16.9832 7.371ZM8.8832 14.175H5.2832C4.9142 14.175 4.6082 13.869 4.6082 13.5C4.6082 13.131 4.9142 12.825 5.2832 12.825H8.8832C9.2522 12.825 9.5582 13.131 9.5582 13.5C9.5582 13.869 9.2522 14.175 8.8832 14.175ZM10.6832 10.575H5.2832C4.9142 10.575 4.6082 10.269 4.6082 9.9C4.6082 9.531 4.9142 9.225 5.2832 9.225H10.6832C11.0522 9.225 11.3582 9.531 11.3582 9.9C11.3582 10.269 11.0522 10.575 10.6832 10.575Z"
        stroke="black"
      />
    </svg>
  );
}
