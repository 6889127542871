import Link from "next/link";

export default function FodCard() {
  return (
    <Link href="/search?filter=🥬 Low FODMAP">
      <div className="flex flex-col justify-center items-center rounded-2xl relative min-w-[8rem] w-32 h-32 bg-[#E3FFD6]">
        <svg
          width="45"
          height="76"
          viewBox="0 0 45 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 left-0"
        >
          <path
            opacity="0.1"
            d="M-35.9734 36.7065C-41.6664 34.0514 -40.984 25.7468 -34.934 24.0566L-14.1427 18.2482C-12.2406 17.7168 -10.6656 16.381 -9.83081 14.5911L-0.706349 -4.97306C1.94878 -10.6661 10.2534 -9.98364 11.9436 -3.93358L17.752 16.8576C18.2833 18.7598 19.6192 20.3348 21.4091 21.1696L40.9732 30.294C46.6662 32.9491 45.9838 41.2537 39.9338 42.9439L19.1425 48.7523C17.2404 49.2837 15.6654 50.6196 14.8306 52.4094L5.70616 71.9736C3.05103 77.6666 -5.25356 76.9842 -6.94375 70.9341L-12.7521 50.1429C-13.2835 48.2408 -14.6194 46.6657 -16.4092 45.831L-35.9734 36.7065Z"
            fill="#559C35"
          />
        </svg>

        <div className="z-10 relative items-center flex flex-col">
          <p className="text-3xl">🥬</p>
          <p className="font-bold text-lg text-[#004707]">Fodmap</p>
        </div>

        <svg
          width="52"
          height="72"
          viewBox="0 0 52 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 right-0"
        >
          <path
            opacity="0.1"
            d="M3.57499 38.3118C-1.48442 34.6993 0.672221 26.6506 6.86007 26.0518L28.1248 23.9938C30.0703 23.8055 31.8422 22.7684 32.9758 21.1546L45.367 3.51494C48.9727 -1.61805 56.9267 0.513218 57.4828 6.76138L59.394 28.2334C59.5689 30.1978 60.5849 31.9819 62.1756 33.1177L79.5624 45.5323C84.6218 49.1449 82.4652 57.1935 76.2773 57.7924L55.0126 59.8504C53.0671 60.0386 51.2952 61.0757 50.1615 62.6895L37.7704 80.3292C34.1647 85.4622 26.2107 83.3309 25.6546 77.0828L23.7434 55.6108C23.5685 53.6464 22.5525 51.8623 20.9618 50.7265L3.57499 38.3118Z"
            fill="#559C35"
          />
        </svg>
      </div>
    </Link>
  );
}
