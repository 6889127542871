export default function HomeOutlineIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.81497 1.74134L2.9573 5.52654C2.14618 6.15741 1.48828 7.50025 1.48828 8.51861V15.1968C1.48828 17.2877 3.19162 19 5.28249 19H15.7188C17.8097 19 19.513 17.2877 19.513 15.2058V8.64478C19.513 7.55432 18.783 6.15741 17.8908 5.53555L12.3212 1.6332C11.0594 0.749983 9.03164 0.795045 7.81497 1.74134Z"
        stroke="#656364"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 15.3951V12.6914"
        stroke="#656364"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
