export default function Hat({ size = 14, ...props }) {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 20 21" fill="none">
      <path
        d="M5.45454 10.0454C5.45454 9.79413 5.25123 9.59082 4.99998 9.59082C4.74873 9.59082 4.54542 9.79413 4.54542 10.0454C4.54542 11.239 5.86201 12.8779 6.01205 13.0612C6.10218 13.1704 6.23266 13.2272 6.36404 13.2272C6.46524 13.2272 6.56735 13.1935 6.65211 13.1242C6.84608 12.9648 6.87404 12.6786 6.71513 12.4841C6.2278 11.8902 5.45454 10.7032 5.45454 10.0454Z"
        fill="black"
      />
      <path
        d="M15 9.59082C14.7487 9.59082 14.5454 9.79413 14.5454 10.0454C14.5454 10.701 13.7722 11.8893 13.2848 12.4846C13.1259 12.6786 13.1543 12.9649 13.3483 13.1242C13.4326 13.1935 13.5347 13.2272 13.6364 13.2272C13.7677 13.2272 13.8978 13.1704 13.9879 13.0612C14.138 12.8779 15.4545 11.239 15.4545 10.0454C15.4545 9.79413 15.2512 9.59082 15 9.59082Z"
        fill="black"
      />
      <path
        d="M10 9.59082C9.74875 9.59082 9.54544 9.79413 9.54544 10.0454V12.7726C9.54544 13.0239 9.74875 13.2272 10 13.2272C10.2512 13.2272 10.4546 13.0239 10.4546 12.7726V10.0454C10.4545 9.79413 10.2512 9.59082 10 9.59082Z"
        fill="black"
      />
      <path
        d="M15 2.31819C14.7245 2.31819 14.4367 2.35463 14.1377 2.41015C13.1366 1.24314 11.6546 0.5 10 0.5C8.34536 0.5 6.86334 1.24314 5.86226 2.41011C5.56329 2.35463 5.27543 2.31815 5.00002 2.31815C2.24297 2.31819 0 4.56116 0 7.31817C0 9.20736 1.06534 10.9248 2.73792 11.7739L2.72906 18.6432C2.72863 19.139 2.92125 19.6051 3.27149 19.9557C3.62215 20.3069 4.08779 20.5 4.58364 20.5H15.4181C16.4409 20.5 17.2727 19.6681 17.2727 18.6454V11.7673C18.9391 10.9159 20 9.20114 20 7.31817C20 4.56116 17.757 2.31819 15 2.31819ZM16.3636 18.6454C16.3636 19.1665 15.9393 19.5909 15.4181 19.5909H4.5836C4.33103 19.5909 4.09354 19.4924 3.91465 19.3135C3.73619 19.1346 3.63767 18.8971 3.63809 18.6441L3.64048 16.8636H16.3636V18.6454ZM16.645 11.0597C16.621 11.0695 16.5984 11.081 16.5771 11.0948C16.5198 11.1303 16.4728 11.1774 16.4377 11.2315C16.4022 11.2852 16.3782 11.3474 16.3685 11.4139C16.3645 11.4392 16.3631 11.4646 16.3636 11.4903V15.9545H3.64163L3.64747 11.4965C3.64789 11.4716 3.64657 11.4463 3.64303 11.4219C3.63328 11.3531 3.60796 11.2892 3.5711 11.2337C3.53646 11.1817 3.4912 11.1365 3.43615 11.1018C3.41352 11.0876 3.38953 11.0752 3.36468 11.065C1.87278 10.4126 0.909075 8.94324 0.909075 7.31817C0.909075 5.06229 2.74414 3.22727 4.99998 3.22727C5.08815 3.22727 5.18121 3.23792 5.27253 3.24512C4.81265 4.04438 4.54542 4.96799 4.54542 5.95454C4.54542 6.20579 4.74873 6.4091 4.99998 6.4091C5.25123 6.4091 5.45454 6.20579 5.45454 5.95454C5.45454 3.44831 7.49377 1.40908 10 1.40908C12.5062 1.40908 14.5454 3.44831 14.5454 5.95454C14.5454 6.20579 14.7487 6.4091 15 6.4091C15.2512 6.4091 15.4545 6.20579 15.4545 5.95454C15.4545 4.96799 15.1873 4.04443 14.7274 3.24512C14.8187 3.23792 14.9118 3.22727 15 3.22727C17.2559 3.22727 19.0909 5.06234 19.0909 7.31817C19.0909 8.93749 18.1312 10.405 16.645 11.0597Z"
        fill="black"
      />
    </svg>
  );
}
