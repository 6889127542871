import { FC, useEffect, useRef, useState } from "react";
import CalendarCard from "@/components/CalendarCard";
import { useRouter } from "next/router";
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import Link from "next/link";
import { useCart } from "@/contexts/cartContext";
import "react-day-picker/dist/style.css";
import Image from "next/image";
import Loader from "@/components/Loaders/Loader";
import RoundedArrowBack from "@/ui/Icons/RoundedArrowBack";
import RoundedMinus from "@/ui/Icons/RoundedMinus";
import { posthog } from "posthog-js";
import BottomNav from "@/components/BottomNav/BottomNav";
// import HistoryIcon from "@/ui/Icons/HistoryIcon";
import SwitchIcon from "@/ui/Icons/SwitchIcon";
import Hat from "@/ui/Icons/Hat";
import BowlIcon from "@/ui/Icons/BowlIcon";
import MilkCard from "@/components/ExploreCards/MilkCard";
import RawCard from "@/components/ExploreCards/RawCard";
import VegCard from "@/components/ExploreCards/VegCard";
import FodCard from "@/components/ExploreCards/FodCard";
import VeganCard from "@/components/ExploreCards/VeganCard";
import { idAndNameToSlug } from "@/lib/slug";
import { usePullToRefresh } from "use-pull-to-refresh";
import { LoaderIcon } from "react-hot-toast";
import { MdArrowUpward, MdOutlineArrowUpward } from "react-icons/md";
import { RiArrowUpDoubleLine } from "react-icons/ri";

export default function Home() {
  const router = useRouter();
  const supabaseClient = useSupabaseClient();
  const user = useUser();
  const [userCalendar, setUserCalendar] = useState<any>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { itemCount } = useCart();
  const [userPersona, setUserPersona] = useState<any>();
  const [invitationError, setInvitationError] = useState<any>();
  const [showInvitationError, setShowInvitationError] =
    useState<boolean>(false);
  const [isShuffling, setShuffling] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { isReady, reload } = useRouter();

  const MAXIMUM_PULL_LENGTH = 240;
  const REFRESH_THRESHOLD = 180;

  useEffect(() => {
    const storedValue = localStorage.getItem("isCalendarsChecked");
    if (storedValue !== null) {
      setIsChecked(JSON.parse(storedValue));
    }
  }, []);

  const fetchCalendar = async () => {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const { data, error } = await supabaseClient
      .from("calendar")
      .select(
        `*, 
        recipe!calendar_recipe_id_fkey(
        *,
        servings:serving(
               *,
               ingredients:serving_ingredient(
                  *,
                  ingredient(
                    *                                                                                  
                  )
               )                                                                                                                                                         
            )

        )`
      )
      .gte("date", todayDate?.toISOString())
      .order("date", { ascending: true });

    if (error) console.log("error", error);
    else {
      localStorage.setItem("cal", JSON.stringify(data));
      setUserCalendar(data);
    }
  };

  const replace = async (
    oldRecipeId: any,
    newRecipeId: any,
    calendarId: any
  ) => {
    setShuffling(true);
    const { data, error } = await supabaseClient
      .from("calendar")
      .update({
        old_recipe_id: oldRecipeId,
        recipe_id: newRecipeId,
        skipped: false,
      })
      .eq("id", calendarId);
    if (error) {
      console.log(error);
    } else {
      fetchCalendar();
      // store recipe feedback
      await supabaseClient.from("recipe_feedback").insert([
        {
          recipe_id: oldRecipeId,
          swipe_rating: false,
          user_id: user?.id,
        },
        {
          recipe_id: newRecipeId,
          swipe_rating: true,
          user_id: user?.id,
        },
      ]);
      setShuffling(false);
    }
  };

  const fetchUserPersona = async () => {
    const { data: persona } = await supabaseClient
      .from("user_persona")
      .select("*")
      .eq("user_id", user?.id)
      .limit(1)
      .single();
    if (persona) {
      setUserPersona(persona);
    }
  };

  const checkPersonaSynced = async () => {
    const { data: userProfile, error: e } = await supabaseClient
      .from("profiles")
      .select("*")
      .eq("id", user?.id)
      .limit(1)
      .single();
    // @ts-ignore
    if (!userProfile?.onboarding_done) {
      router.push("/begin");
    }
  };

  // const fetchAndStoreInitialRecommendation = async () => {
  //   // fetch all recipes id that already like or disliked
  //   const { data: feedbackRecipes } = await supabaseClient
  //     .from("recipe_feedback")
  //     .select("*");
  //   // fetch the recipes ids from recommendations
  //   const feedbacks: any[] = feedbackRecipes?.map((i: any) => {
  //     return {
  //       RecipeID: i?.recipe_id?.toString(),
  //       feedback: i?.swipe_rating,
  //     };
  //   }) as any[];
  //   // @ts-ignore

  //   const storedCalender = localStorage.getItem("cal") || "[]" as any;

  //   try {
  //     const { data } = await supabaseClient.functions.invoke(
  //       "user-recommendations",
  //       {
  //         body: { feedbacks: feedbacks, ignore: JSON.parse(storedCalender)?.map((r: any) => r?.recipe_id) },
  //         method: "POST",
  //       }
  //     );

  //     localStorage.setItem("initialRecommendation", JSON.stringify(data));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    const hash = router?.asPath?.split("#")?.[1];
    const hashParameters: any = {};
    if (hash) {
      hash.split("&").forEach((item) => {
        hashParameters[item.split("=")[0]] = decodeURIComponent(
          item.split("=")[1]
        );
      });
    }
    if (hashParameters?.error) {
      setShowInvitationError(true);
      setInvitationError(hashParameters);
    } else if (hashParameters?.type === "invite") {
      router.push(`/onboarding?type=invite&url=onboard`);
    } else if (user?.email) {
      fetchCalendar();
      fetchUserPersona();

      posthog.identify(user.email, {
        email: user.email,
        name: user?.user_metadata?.full_name ?? user?.user_metadata?.name ?? "",
      });
    }
    if (!user) {
      router.push("/signin");
    } else {
      // fetchAndStoreInitialRecommendation();
    }
    if (user) {
      checkPersonaSynced();
    }
  }, [user]);

  const callPlanner = async () => {
    const ignoreItem = userCalendar?.map((r: any) => r?.recipe_id);

    const { data: feedbackRecipes }: any = await supabaseClient
      .from("recipe_feedback")
      .select("*");
    // fetch the recipes ids from recommendations
    const feedbacks: any[] = feedbackRecipes?.map((i: any) => {
      return {
        RecipeID: i?.recipe_id?.toString(),
        feedback: i?.swipe_rating,
      };
    }) as any[];

    if(feedbacks && ignoreItem){
      const { data: plannerData, error: plannerError }: any =
      await supabaseClient.functions.invoke("calender-planner", {
        body: {
          feedbacks: feedbacks,
          ignore: ignoreItem,
        },
        method: "POST",
      });
      if (plannerError) {
        console.log("error plannerError", plannerError, feedbacks, ignoreItem);
      } else {
        fetchCalendar();
      }
    }
  };

  const { isRefreshing, pullPosition } = usePullToRefresh({
    // you can choose what behavior for `onRefresh`, could be calling an API to load more data, or refresh whole page.
    onRefresh: callPlanner,
    maximumPullLength: MAXIMUM_PULL_LENGTH,
    refreshThreshold: REFRESH_THRESHOLD,
    isDisabled: !isReady,
  });

  let mountedRef = true;

  useEffect(() => {
    // console.log(userCalendar, "userCalendar");
    if (userCalendar && mountedRef && userCalendar.length != 7) {
      mountedRef = false;
      callPlanner();
    }
  }, [userCalendar]);

  useEffect(() => {
    return () => {
      if (typeof window !== "undefined" && user?.email) {
        (
          (function (t: any, u: any, e: any, m: any, i: any, l: any, io: any) {
            t["TuemilioObject"] = m;
            t[m] =
              t[m] ||
              function () {
                (t[m].q = t[m].q || []).push(arguments);
              };
            t[m].id = process.env.NEXT_PUBLIC_TUEMILIO_KEY;
            (l = u.createElement(e)), (io = u.getElementsByTagName(e)[0]);
            l.id = m;
            l.src = i;
            l.async = 1;
            io.parentNode.insertBefore(l, io);
          }) as any
        )(
          window,
          document,
          "script",
          "Tuemilio",
          "https://tuemilio.com/assets/js/modal/4.0/tuemilio-modal.js"
        );
        (window as any).Tuemilio("init", {
          form: {
            disabled: true,
          },
          dashboard: {
            disabled: true,
          },
        }),
          (window as any).Tuemilio("onSubscriberUnidentified", function () {
            (window as any).Tuemilio("createSubscriber", {
              address: user.email,
            }),
              (window as any)?.Tuemilio("getDashboard", {
                address: user?.email,
              });
          });
      }
    };
  }, []);

  return (
    <section className="h-screen flex flex-col max-w-lg mx-auto relative">
      <div className="pb-32">
        {/* Home Screen */}
        <div className="flex justify-between items-center py-4 mb-4 px-3 bg-white">
          <div className="flex justify-start items-center flex-grow gap-3">
            <div className="w-10 h-10 rounded-full overflow-hidden object-cover bg-[rgba(223, 167, 189, 0.2)] ring-white ring-2">
              <Link href={"/user/profile"}>
                <Image
                  width={40}
                  height={40}
                  className="object-cover w-10"
                  src={
                    user?.user_metadata?.picture ??
                    (userPersona?.family_role === null
                      ? "/avatar2.png"
                      : (userPersona?.family_role === "Mom" &&
                          "/memoji/Mom.png") ||
                        (userPersona?.family_role === "Dad" &&
                          "/memoji/Dad.png") ||
                        (userPersona?.family_role === "Son" &&
                          "/memoji/Son.png") ||
                        (userPersona?.family_role === "Daughter" &&
                          "/memoji/Daughter.png"))
                  }
                  alt={user?.user_metadata?.name ?? "avatar"}
                />
              </Link>
            </div>
            <div className="flex-grow">
              <h2 className="text-lg font-semibold text-[#343233]">
                Hi,{" "}
                <span>
                  {user?.user_metadata?.full_name?.split(" ")?.slice(0, 1)}
                </span>
              </h2>
              <span className="text-base font-normal text-[#656364]">
                What's for dinner?
              </span>
            </div>
          </div>
          <div className="flex items-center gap-x-4">
            {/*<button*/}
            {/*  onClick={() => setShowHistory(!showHistory)}*/}
            {/*  className="bg-green-100 p-2 rounded-full"*/}
            {/*>*/}
            {/*  <HistoryIcon />*/}
            {/*</button>*/}
            {/* <div
              onClick={() => {
                router.push("/calenderHistory");
              }}
              className="h-10 cursor-pointer w-10 rounded-full items-center flex justify-center object-cover"
            >
              <HistoryIcon />
            </div> */}
          </div>
        </div>
        {/* <hr className="h-px bg-[#F0EEEE] border-0 mt-2 mb-5" /> */}
        {/* Scrollable Area */}

        {/* {console.log(userCalendar, "userCalendar") as any} */}

        {userCalendar?.length > 0 ? (
          <div className="px-3 flex flex-col gap-4">
            {!isChecked ? (
              <>
                <div className="flex items-center justify-between">
                  <p className="text-[#007D32] font-medium text-lg">
                    🍽️ Tonight
                  </p>
                  <button
                    className="flex"
                    onClick={() => {
                      const randomIndex =
                        Math.floor(Math.random() * (userCalendar.length - 1)) +
                        1;
                      replace(
                        userCalendar[0].recipe_id,
                        userCalendar[randomIndex].recipe_id,
                        userCalendar[0].id
                      );
                    }}
                  >
                    {" "}
                    Shuffle
                    {isShuffling ? <Loader size={6} /> : <SwitchIcon />}
                  </button>
                </div>
                <Link
                  href={`/${idAndNameToSlug(
                    userCalendar[0].recipe_id,
                    userCalendar[0].recipe.name
                  )}`}
                >
                  <div className="bg-[#F4E7EC] rounded-3xl px-7 py-10 flex flex-col gap-5 overflow-hidden">
                    <div className="relative">
                      <img
                        className="w-40 h-40 rounded-full bg-white absolute -top-10 -right-20"
                        src={userCalendar[0].recipe.imageURL}
                      />
                      <div className="flex flex-col gap-4 max-w-[12rem] text-[#343233]">
                        <p className="font-semibold text-xl">
                          {userCalendar[0].recipe.name}
                        </p>
                        <div className="flex w-full justify-between max-w-[10rem]">
                          <div className="flex flex-col items-center gap-1">
                            <Hat size={20} />
                            <p>{userCalendar[0].recipe.prepTime} mins</p>
                          </div>
                          <div className="border-r border-gray-300"></div>
                          <div className="flex flex-col items-center gap-1">
                            <BowlIcon size={23} />
                            <p>{userCalendar[0].recipe.cookTime} mins</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text=[#434343] line-clamp-3">
                      {userCalendar[0].recipe.description}
                    </p>
                    <div className="flex gap-4 flex-wrap">
                      {userCalendar[0].recipe.dietTags.map((item: any) => (
                        <div className="bg-white rounded-xl text-[#434343] py-2 px-5">
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                </Link>

                <div className="flex flex-col gap-4">
                  <p className="text-[#343233] font-medium">
                    Suggested meals for the week
                  </p>
                  <div className="flex w-full flex-nowrap gap-2 overflow-x-scroll">
                    {userCalendar
                      .filter((item: any, index: any) => index > 0 && index < 7)
                      .map((item: any, index: number) => {
                        const rotationColors = [
                          "#BAE7CB  ",
                          "#DEC9F5",
                          "#FFF7CA",
                        ];
                        const rotatedColor =
                          rotationColors[index % rotationColors.length];
                        return (
                          <Link
                            href={`/${idAndNameToSlug(
                              item?.recipe_id,
                              item?.recipe?.name
                            )}`}
                            key={item.recipe_id}
                          >
                            <div
                              className="rounded-2xl overflow-hidden relative min-w-[8rem] w-32 h-36"
                              style={{ backgroundColor: rotatedColor }}
                            >
                              <img
                                className="w-28 h-28 rounded-full bg-white absolute -bottom-7 -left-5 z-0"
                                src={item.recipe.imageURL}
                              />
                              <div className="z-10 relative">
                                <p
                                  className="text-[#343233] font-bold p-4 text-sm"
                                  style={{
                                    textShadow: `-1px 1px 5px ${rotatedColor}`,
                                  }}
                                >
                                  {item.recipe.name}
                                </p>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                  </div>
                </div>
              </>
            ) : (
              <div className="flex flex-col space-y-2 gap-y-2 grow overflow-y-scroll scrollbar-hide">
                {userCalendar.map((item: any, key: string) => (
                  <CalendarCard
                    item={item}
                    key={key}
                    fetchCalendar={fetchCalendar}
                  />
                ))}
              </div>
            )}

            <div className="flex flex-col gap-4">
              <p className="text-[#343233] font-medium">Explore</p>
              <div className="flex w-full flex-nowrap gap-2 overflow-x-scroll">
                <FodCard />
                <RawCard />
                <VegCard />
                <MilkCard />
                <VeganCard />
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full rounded-3xl flex justify-center items-center flex-col pt-52">
            <div
              style={{
                top: (isRefreshing ? REFRESH_THRESHOLD : pullPosition) / 3,
                opacity: isRefreshing || pullPosition > 0 ? 1 : 0,
              }}
              className="bg-[#15BC6E] w-full fixed inset-x-1/2 z-30 h-40 -translate-y-36 -translate-x-1/2 p-2"
            >
              <div
                className={`h-full w-full flex flex-col items-center justify-end`}
              >
                {isRefreshing ? (
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className={`w-6 h-6 mr-2 text-gray-200 animate-spin fill-[#6f64ff]`}
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  <RiArrowUpDoubleLine color="white" size={30} />
                )}
                <p className="text-white text-center">Relese to refresh feed</p>
              </div>
            </div>
            <Loader />
            <div className={"flex flex-col items-center justify-center my-5"}>
              <p>We are generating your week's plan</p>
              <p className="text-sm text-gray-500">(you can pull to refresh)</p>
            </div>
          </div>
        )}

        {/* <div className="fixed bottom-4 right-4 bg-[#15BC6E] h-14 w-14 rounded-[20px] grid place-items-center">
          <button className="" onClick={() => setShowDatePicker(true)}>
            <CartIcon />
          </button>
          <span className="absolute -top-2 -right-1 rounded-full text-white bg-[#1B6458] text-sm w-6 h-6 grid place-items-center">
            {itemCount}
          </span>
        </div> */}
      </div>
      {/* Model History picker */}
      {showDatePicker && (
        <GroceryListModel
          closeModal={() => setShowDatePicker(false)}
          userCalendar={userCalendar}
        />
      )}
      {showInvitationError && (
        <InvitationError
          closeModal={() => {
            setShowInvitationError(false);
            router.push("/start");
          }}
          error={invitationError}
        />
      )}
      <BottomNav onClickCart={() => setShowDatePicker(true)} />
    </section>
  );
}

const GroceryListModel: FC<any> = ({ closeModal, userCalendar }) => {
  const router = useRouter();

  return (
    <div className="fixed left-0 top-0 z-[1055] h-full overflow-y-auto overflow-x-hidden outline-none bg-transparent w-full flex justify-center items-center">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm "
        onClick={closeModal}
      />
      <div className="pointer-events-none flex relative transition-all duration-300 max-w-md px-4 ease-in-out min-h-[calc(100%-1rem)] w-full items-center">
        <div className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col border-none rounded-[18px] overflow-hidden bg-white bg-clip-padding text-current shadow-lg outline-none">
          <div className="z-10 w-full min-w flex flex-col">
            <div className="flex items-center py-3 px-5 bg-[#15BC6E]">
              <button onClick={() => closeModal()}>
                <RoundedArrowBack />
              </button>
              <p className="text-lg text-center w-full text-white">
                Grocery List
              </p>
            </div>
            <div className="py-3 px-5 max-h-96 overflow-y-scroll">
              {userCalendar.map((item: any, key: string) => (
                <div key={key}>
                  <IngredientCard
                    recipe={item.recipe}
                    date={item.date}
                    skipped={item.skipped}
                  />
                </div>
              ))}
            </div>
            <div className="flex items-center py-3 px-5 justify-center">
              <button
                className="bg-[#15BC6E] text-white font-semibold text-lg py-3 px-10 rounded-xl hover:scale-105 transition-all"
                onClick={() => router.push("/shoppingcart")}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IngredientCard: FC<any> = ({ recipe, date, skipped }) => {
  const { addItemToCart, removeItemFromCart } = useCart();
  const [selected, setSelected] = useState(skipped ? false : true);

  const ingredientDate = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  }).format(new Date(date));

  useEffect(() => {
    if (selected) {
      recipe?.ingredients_meta?.forEach((ing: any) => {
        addItemToCart(ing, recipe);
      });
    }
  }, []);

  return (
    <div
      className={`flex py-3 px-4 mb-3 gap-3 border-[0.5px] border-[#E4E2E3] items-center rounded-2xl hover:scale-[1.01] duration-200 transition-transform ease-out hover:bg-slate-100 ${
        selected ? "bg-[#E3F5EA] border-none" : ""
      }`}
    >
      <div className="text-xs whitespace-nowrap">{ingredientDate}</div>
      <div className="flex-grow font-medium text-ellipsis overflow-hidden whitespace-nowrap">
        {skipped ? "Night Off" : <>{recipe.name}</>}
      </div>
      {!skipped && (
        <button
          className="mr-4"
          onClick={() => {
            if (!selected) {
              recipe?.ingredients_meta?.forEach((ing: any) => {
                addItemToCart(ing, recipe);
              });
            } else {
              recipe?.ingredients_meta?.forEach((ing: any) => {
                removeItemFromCart(ing, recipe);
              });
            }

            setSelected(!selected);
          }}
        >
          {!selected ? (
            <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
              <path
                fill="#15BC6E"
                d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm5 11h-4v4H9v-4H5V9h4V5h2v4h4v2z"
              ></path>
            </svg>
          ) : (
            <RoundedMinus />
          )}
        </button>
      )}
    </div>
  );
};

const InvitationError: FC<any> = ({ closeModal, error }) => {
  const router = useRouter();
  return (
    <div className="fixed left-0 top-0 z-[1055] h-full overflow-y-auto overflow-x-hidden outline-none bg-transparent w-full flex justify-center items-center">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm "
        onClick={closeModal}
      />
      <div className="pointer-events-none flex relative transition-all duration-300 max-w-md px-4 ease-in-out min-h-[calc(100%-1rem)] w-full items-center">
        <div className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col border-none rounded-[18px] overflow-hidden bg-white bg-clip-padding text-current shadow-lg outline-none">
          <div className="z-10 w-full min-w flex flex-col justify-center py-10">
            <p
              className={
                "text-center pb-5 text-red-500 font-medium text-[18px]"
              }
            >
              {error?.error_description?.replaceAll("+", " ")}
            </p>
            <div className={"flex justify-center"}>
              <button
                className={
                  " py-2 px-2 bg-[#15BC6E] text-white rounded-xl hover:scale-105"
                }
                onClick={() => {
                  router.push("/signup");
                }}
              >
                Continue with a new account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
